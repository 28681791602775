import axios from "axios";
import React, { useState } from "react";
import { ProductCard } from "./ProductCard";

const ProductSelection = ({
  userId,
  token,
  url,
  purchasedPhysical,
  physical,
  sst,
}) => {
  const [selected, setSelected] = useState(null);
  // const [selectedWorkshop, setSelectedWorkshop] = useState(null) // [null, product_id]
  const today = new Date();
  const march = new Date(today.getFullYear(), 2, 1); // Months are 0-indexed in JavaScript

  let sstRate = today < march ? 6 : 8;

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input type="hidden" name="product_ids" value={[selected]} />

        <ProductCard
          cardTitle={"29th SICOT YSIM 2024"}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null);
              // setSelectedWorkshop(null)
            } else {
              // setSelectedWorkshop(null)
            }
          }}
          handleRadio={() => {
            // setSelectedWorkshop(null)
          }}
        />
        {/* <h6 className="text-center text-muted mt-4">OR</h6> */}

        {/* <ProductCard
          cardTitle={'Ultrasound Workshop'}
          products={workshop}
          selectedState={selectedWorkshop}
          setSelectedState={setSelectedWorkshop}
          activated={(selected || purchasedPhysical)}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedWorkshop(null)
            }
          }}
        /> */}

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur {sstRate}% SST
          </strong>
        )}
        <button
          type="submit"
          className="btn text-white btn-primary d-block mx-auto mt-4"
          disabled={selected === null}
        >
          Purchase Pass
        </button>
      </form>
    </div>
  );
};

export default ProductSelection;
